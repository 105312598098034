import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material';
import * as _ from 'lodash';
import { Edits } from '../../shared/models/common';
import { LinguistService } from '../../shared/services/linguist.service';

@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.less']
})
export class AuditTrailComponent implements OnInit {
  @Input() tableName: string;
  @Input() entityId: number;

  @Output() assignEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatTable) table: MatTable<any>;
  editLog: Edits[];

  public columnNames: string[] = ['username', 'datetime', 'original', 'requestBody', 'action', 'button'];

  constructor(private linguisticsService: LinguistService) {
  }

  ngOnInit() {
    if (this.tableName && this.entityId) {
      this.linguisticsService.getAuditTrail(this.tableName, this.entityId).then(edits => {
        this.editLog = edits;
        for (let editEntry of this.editLog) {
          try {
            if (editEntry.requestBody)
              editEntry.parsedRequestBody = this.removeFields(JSON.parse(editEntry.requestBody));
            if (editEntry.original)
              editEntry.parsedOriginal = this.removeFields(JSON.parse(editEntry.original));
          } catch (error) {
            editEntry.parseError = error;
            console.error("Cannot parse body: " + error);
          }
        }
      });
    }
  }

  public displayProperty(propVal: any): string {
    let concatenated: string = "";
    if (propVal instanceof Array) {
      for (let arrayMemb of propVal)
        if (concatenated.length > 0)
          concatenated += ", " + this.displayProperty(arrayMemb);
        else
          concatenated = this.displayProperty(arrayMemb);
      return concatenated;
    }
    else {
      if (typeof propVal == "object") {
        const OBJECT_PREFIX: string = " [";
        concatenated += OBJECT_PREFIX;
        for (let subpr in propVal) {
          if (concatenated.length > OBJECT_PREFIX.length)
            concatenated += ", ";
          concatenated += subpr + "=" + this.displayProperty(propVal[subpr]);
        }
        concatenated += "] ";
        return concatenated;
      }
      return propVal.toString();
    }
  }

  public shortened(str: string): string {
    if (!str || str.length < 20)
      return str;
    else
      return str.substr(0, 20) + '...';
  }

  public parseRequestBody(jsonColumn: string): string[] {
    if (jsonColumn) {
      try {
        const arrayOfData = JSON.parse(jsonColumn);
        const keys = Object.keys(arrayOfData);
        const requestDataArray: string[] = [];
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          const element = arrayOfData[key];
          const data = (typeof element === 'object' && element) ? JSON.stringify(element) : element;
          const str = key + ':' + data;
          requestDataArray.push(str);
        }
        return requestDataArray;
      } catch (error) {
        console.error(error);
      }
    } else {
      return [];
    }
  }

  public parseStr(str: string, index = 0): string {
    const array: string[] = str.split(':');
    if (index) {
      array.shift();
      return array.join(':');
    } else {
      return array[0];
    }
  }

  public assign(original, requestBody) {    
    original = this.removeFields(original);
    requestBody = this.removeFields(requestBody);
    this.assignEvent.emit(Object.keys(original || {}).length > 0 ? original : requestBody);
  }

  //remove additional fields
  removeFields(source: any) {
    return _.omitBy(source, (_value, keyName) => {
      return keyName == 'id' || ['create', 'Update', 'request', 'legacy', 'relevant', 'advancedCriteriaDescription'].find((k: string) => {
        return keyName.indexOf(k) > -1
      });
    });
  }
}
