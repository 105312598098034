import {
  ChangeDetectionStrategy,
  ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output
} from '@angular/core';

import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';
import {Subscription} from 'rxjs/Subscription';

import {emptyLinguisticRangeLocalization, LinguisticRangeLocalization} from '../../../shared/models/range';
import {createFlatArrayCopy, toJsonObject} from '../../../shared/helpers/object.helper';
import {RangeService} from '../../../shared/services/range.service';
import {LampUpdateResponse} from '../../../shared/models/common';
import {Language} from '../../../shared/models/language';

@Component({
  selector: 'app-range-localization-table',
  templateUrl: './range-localization-table.component.html',
  styleUrls: ['./range-localization-table.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RangeLocalizationTableComponent implements OnInit, OnDestroy {
  @Input() rangeId: number;
  @Input() doesUserHaveAccessDelete: boolean;
  @Input() addNewEntryObservable: Observable<boolean>;

  @Output() addNewElementEvent: EventEmitter<any> = new EventEmitter();

  public rangeLocalizationList: LinguisticRangeLocalization[];
  public displayedColumns: string[];

  public errorMessageBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageBehaviorSubject.asObservable();

  private subscription: Subscription;
  private isNew: boolean;

  public initialRangeLocalizationList: LinguisticRangeLocalization[];
  public isEmptyRangeLocalizationList: boolean;
  public editId: number = -1;
  public localizationLanguageIdToEdit: number;

  public showProgressbar: boolean;

  private rangeLocalizationBeforeEdit: LinguisticRangeLocalization;
  private emptyLinguisticRangeLocalization: LinguisticRangeLocalization = toJsonObject(emptyLinguisticRangeLocalization) as LinguisticRangeLocalization;

  constructor(private rangeService: RangeService, private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.initialize();
    this.getRangeLocalizationList();
    this.subscribeOnAddNewEntry();
  }

  private subscribeOnAddNewEntry(): void {
    this.subscription = this.addNewEntryObservable.subscribe((shouldAddEntry: boolean) => {
      if (shouldAddEntry) {
        this.addNewRowInTheTable();
      }
    });
  }

  private initialize(): void {
    this.displayedColumns = ['language-id', 'description'];
    this.emptyLinguisticRangeLocalization.rangeId = this.rangeId;
  }

  private initializeTable(rangeLocalizationList?: LinguisticRangeLocalization[]): void {
    rangeLocalizationList.map((element, index) => {
      element.index = index;
    });

    if (rangeLocalizationList) {
      this.initialRangeLocalizationList = createFlatArrayCopy(rangeLocalizationList) as LinguisticRangeLocalization[];
    } else {
      this.rangeLocalizationList = [];
      this.initialRangeLocalizationList = [];
    }

    this.isEmptyRangeLocalizationList = !this.rangeLocalizationList || !Boolean(this.rangeLocalizationList.length);
    this.changeDetectorRef.markForCheck();
  }

  private addNewRowInTheTable(): void {
    this.revert();

    if (!this.rangeLocalizationList) {
      this.rangeLocalizationList = [];
    }

    const index = this.rangeLocalizationList.length;
    this.emptyLinguisticRangeLocalization = toJsonObject(emptyLinguisticRangeLocalization) as LinguisticRangeLocalization;
    this.emptyLinguisticRangeLocalization.index = index;
    this.rangeLocalizationList.push(this.emptyLinguisticRangeLocalization);

    this.addNewElementEvent.emit(true);
    this.isNew = true;
    this.saveExistingRowBeforeEdit(index);

    this.isEmptyRangeLocalizationList = !this.rangeLocalizationList || !Boolean(this.rangeLocalizationList.length);
  }

  public edit(index: number): void {
    this.isNew = false;
    this.clearTheErrorMessage();
    this.revert();

    this.saveExistingRowBeforeEdit(index);
  }

  public saveExistingRowBeforeEdit(index: number): void {
    this.editId = index;
    const rangeLocalizationBeforeEdit = this.rangeLocalizationList.find(element => element.index === index);
    this.localizationLanguageIdToEdit = rangeLocalizationBeforeEdit.languageId || 0;

    this.rangeLocalizationBeforeEdit = toJsonObject(rangeLocalizationBeforeEdit) as LinguisticRangeLocalization;
  }

  private getRangeLocalizationList(): Promise<void> {
    this.showProgressbar = true;
    return this.rangeService.getRangeLocalizationList(this.rangeId)
      .then((rangeLocalizationList: LinguisticRangeLocalization[]) => {
        this.showProgressbar = false;
        this.rangeLocalizationList = rangeLocalizationList;
        this.initializeTable(rangeLocalizationList);

        return Promise.resolve();
      });
  }

  private reactOnTheServerResponse(successfulResponseMessage: LampUpdateResponse): Promise<void> {
    if (successfulResponseMessage.success) {
      return this.getRangeLocalizationList().then(() => {
        this.revert();
      });
    } else {
      this.showProgressbar = false;
      this.showErrorMessage(successfulResponseMessage.error);
      this.changeDetectorRef.markForCheck();
      return Promise.resolve();
    }
  }

  public deleteAfterUserConfirmation(languageId: number): Promise<void> {
    this.clearTheErrorMessage();
    this.showProgressbar = true;
    return this.rangeService.deleteRangeLocalizationById(this.rangeId, languageId)
      .then((successfulResponseMessage: LampUpdateResponse) => {
        return this.reactOnTheServerResponse(successfulResponseMessage);
      });
  }

  public revert(): void {
    this.clearTheErrorMessage();
    this.rangeLocalizationBeforeEdit = toJsonObject(this.emptyLinguisticRangeLocalization) as LinguisticRangeLocalization;
    this.rangeLocalizationList = createFlatArrayCopy(this.initialRangeLocalizationList) as LinguisticRangeLocalization[];
    this.editId = -1;
    this.changeDetectorRef.markForCheck();
  }

  public update(rangeLocalization: LinguisticRangeLocalization): Promise<void> {
    this.clearTheErrorMessage();
    this.showProgressbar = true;

    const changes = toJsonObject(rangeLocalization) as LinguisticRangeLocalization;
    delete changes.index;
    changes.rangeId = this.rangeId;

    if (this.isNew) {
      return this.rangeService.createRangeLocalization(changes).then((successfulResponseMessage: LampUpdateResponse) => {
        return this.reactOnTheServerResponse(successfulResponseMessage);
      });
    } else {
      return this.rangeService.getRangeLocalizationById(
        this.rangeLocalizationBeforeEdit.rangeId,
        this.rangeLocalizationBeforeEdit.languageId)
        .then((linguisticRangeLocalization: LinguisticRangeLocalization) => {
          return this.rangeService.updateRangeLocalization(changes).then((successfulResponseMessage: LampUpdateResponse) => {
            return this.reactOnTheServerResponse(successfulResponseMessage);
          });
        });
    }
  }

  public clearTheErrorMessage(): void {
    this.showErrorMessage('');
  }

  private showErrorMessage(error?: string) {
    this.errorMessageBehaviorSubject.next(error);
  }

  public updateLanguage(language: Language): void {
    this.rangeLocalizationList[this.editId].languageId = (language) ? language.id : 0;
    this.changeDetectorRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.editId = -1;
    this.subscription.unsubscribe();
  }

}
